import { withQuery, withBase } from "ufo";

export enum AuthView {
  Login = "login",

  Register = "register",
  Confirm = "confirm",

  Reset = "reset",
  Recover = "recover",
}

export const useAuth = createSharedComposable(() => {
  const isAuthDialogOpen = useState("isAuthDialogOpen", () => false);

  const confirmationToken = useState<string | undefined>(
    "confirmationToken",
    undefined
  );
  const authView = useState<AuthView>("authView", () => AuthView.Login);

  const openAuthDialog = (tab?: MaybeRef<AuthView>) => {
    isAuthDialogOpen.value = true;
    authView.value = unref(tab) || AuthView.Login;
  };

  const config = useRuntimeConfig();

  const { useRest, useAuth, useUsers } = useCms();
  const { url } = useRest();
  const { login, logout, passwordRequest, passwordReset, user, setUser } =
    useAuth();

  const { updateUser, deleteUsers } = useUsers();

  const isLoggedIn = ref(false);

  const updateIsLoggedIn = () => (isLoggedIn.value = !!user.value?.id);

  updateIsLoggedIn();
  watch(user, updateIsLoggedIn);
  tryOnMounted(updateIsLoggedIn);

  const route = useRoute();
  const router = useRouter();

  const localePath = useLocalePath();

  const redirectUri = computed(() =>
    withQuery(withBase("/auth", config.public.baseUrl), {
      next: route.fullPath,
    })
  );

  const loginWithGoogle = async () => {
    return await navigateTo(
      withQuery(withBase("/auth/login/google", url.href), {
        redirect: redirectUri.value,
      }),
      { external: true }
    );
  };

  const loginWithFacebook = async () => {
    return await navigateTo(
      withQuery(withBase("/auth/login/facebook", url.href), {
        redirect: redirectUri.value,
      }),
      { external: true }
    );
  };

  const loginWithEmail = async (
    email: string,
    password: string,
    otp?: string
  ) => {
    const result = await login(email, password, {
      options: { otp },
      updateStates: true,
      readMe: { updateState: true },
    });
    await refreshUser();
    return result;
  };

  const registerWithEmail = async (
    first_name: string,
    email: string,
    password: string
  ) => {
    return await fetch(
      withQuery(withBase("/users/register", url.href), {
        first_name,
        email,
        password,
        verification_url: withBase("/verify-email", config.public.baseUrl),
      }),
      { method: "POST", credentials: "include" }
    );
  };

  const updateUserName = async (name: string) => {
    if (user.value?.id) {
      return updateUser(user.value.id, { first_name: name }, {});
    }
  };

  const updateUserEmail = async (email: string) => {
    if (user.value?.id) {
      return updateUser(user.value.id, { email }, {});
    }
  };

  const updateUserPassword = async (password: string) => {
    if (user.value?.id) {
      return updateUser(user.value.id, { password }, {});
    }
  };

  const deleteUser = async () => {
    if (user.value?.id) {
      return deleteUsers([user.value.id]);
    }
  };

  const confirmEmail = async (token: string) => {
    return await fetch(
      withQuery(withBase("/users/register/verify-email", url.href), {
        token,
      }),
      { method: "GET", credentials: "include" }
    );
  };

  const logoutUser = async () => {
    await logout();

    if (
      Array.isArray(route.meta.middleware)
        ? route.meta.middleware.includes("auth")
        : route.meta.middleware === "auth"
    ) {
      await navigateTo(localePath({ name: "index" }));
    }
  };

  const isFetchingUser = ref(true);

  const refreshUser = async () => {
    isFetchingUser.value = true;

    const data = await fetch(withQuery(withBase("/users/me", url.href), {}), {
      credentials: "include",
    }).catch(() => null);

    const userData = await data
      ?.json()
      ?.then((res) => res?.data || null)
      .catch(() => null);

    if (userData) {
      await setUser(userData);
    } else {
      await setUser(undefined);
    }

    isFetchingUser.value = false;

    return userData;
  };

  tryOnMounted(() => refreshUser());

  const updateConsents = async (
    data?: MaybeRef<{
      general?: MaybeRef<string[]>;
      marketing?: MaybeRef<string[]>;
    }>
  ) => {
    if (!user.value?.id) return;

    data = unref(data);

    const userData = {
      general_consents: unref(data?.general),
      marketing_consents: unref(data?.marketing),
    };

    return await updateUser(user.value.id, userData, {});
  };

  const strongPasswordRegex =
    /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{';'?>.<,])(?!.*\s).*$/;

  const isPasswordStrong = (password: string) => {
    return strongPasswordRegex.test(password);
  };

  const requestPasswordReset = async ({
    email,
    reset_url,
  }: {
    email: string;
    reset_url: string;
  }) => {
    return await passwordRequest(email, reset_url);
  };

  const resetPassword = async ({
    token,
    password,
  }: {
    token: string;
    password: string;
  }) => {
    return await passwordReset(token, password);
  };

  return {
    isAuthDialogOpen,

    confirmationToken,
    authView,

    user,
    isLoggedIn,

    redirectUri,

    openAuthDialog,

    loginWithGoogle,
    loginWithFacebook,
    loginWithEmail,

    registerWithEmail,

    requestPasswordReset,
    resetPassword,

    confirmEmail,

    logoutUser,

    updateUserName,
    updateUserEmail,
    updateUserPassword,

    deleteUser,

    isFetchingUser,
    refreshUser,

    updateConsents,

    strongPasswordRegex,
    isPasswordStrong,
  };
});
